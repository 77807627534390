import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea
} from "@chakra-ui/react";
import { CommunicationCategory, useCommunicationTabList, useGetCommunicationUserList } from "@new-wars/core";
import { observer } from "mobx-react";
import { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useCommunicationStore } from "../../hooks";

type NewMessageModalProps = {};

const NewMessageModal: NextPage<NewMessageModalProps> = observer(() => {
  const { t } = useTranslation("common");
  const {
    sendMessage,
    setNewMessage,
    newMessage,
    newMessageTitle,
    setNewTitle,
    isOpenNewMessageModal,
    resetModal,
    loading
  } = useCommunicationStore();
  const { mutateCommunicationTabList } = useCommunicationTabList(CommunicationCategory.MessagesSent);
  const [sendToId, setUserId] = useState(-1);
  const [userName, setUserName] = useState("");
  const { communicationUserName } = useGetCommunicationUserList({ userName, limit: 3 });
  const onCloseModal = () => {
    mutateCommunicationTabList();
    resetModal();
  };

  return (
    <Box>
      <Modal isOpen={isOpenNewMessageModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Nowa wiadomość"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder={"Szukaj"} value={userName} onChange={event => setUserName(event.target.value)} mb={2} />
            {communicationUserName?.communicationUserList.map(user => (
              <Box display={"flex"} my={2} alignItems={"center"}>
                <Text mr={4}>{user.userLogin}</Text>
                <Button
                  isDisabled={user.userId === sendToId}
                  onClick={() => {
                    setUserId(user.userId);
                    setUserName(user.userLogin);
                  }}
                >
                  {t("select")}
                </Button>
              </Box>
            ))}
            <Input
              placeholder={"Tytuł"}
              value={newMessageTitle}
              onChange={event => setNewTitle(event.target.value)}
              mb={2}
            />
            <Box display={"flex"}>
              <Textarea
                value={newMessage}
                onChange={event => setNewMessage(event.target.value)}
                placeholder="Napisz nową wiadomość"
                size="sm"
              />
              <Button
                isDisabled={sendToId === -1}
                isLoading={loading}
                onClick={() => sendMessage(sendToId, mutateCommunicationTabList)}
              >
                {t("send")}
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseModal}>
              {t("close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});

export default NewMessageModal;
