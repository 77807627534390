import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { ProgressItem } from "@new-wars/common";
import { DefenseProgress, useDefenceDescription, useDefenseSystems, useGameSummaryProgress } from "@new-wars/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { useDefenseStore, useUserStore } from "../../../hooks";

const DefenseProgressItem = observer(
  ({ defenseProgress, small = false }: { defenseProgress: DefenseProgress; small?: boolean }) => {
    const { cancelDefenceSystem, onFinishDefenceSystem, loading } = useDefenseStore();
    const { currentPlanetId } = useUserStore();
    const { defenceDesc } = useDefenceDescription();
    const { mutateGameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
    const { mutateDefenseSystems } = useDefenseSystems(currentPlanetId);
    const title = useMemo(
      () => defenceDesc?.find(x => x.id === defenseProgress?.defenseSystemId)?.name ?? "",
      [defenseProgress, defenceDesc]
    );
    const onFinish = useCallback(
      () =>
        onFinishDefenceSystem(
          defenseProgress.id,
          defenseProgress.planetId,
          mutateGameSummaryProgress,
          mutateDefenseSystems
        ),
      [defenseProgress.id, defenseProgress.planetId]
    );
    const onCancel = useCallback(
      () =>
        cancelDefenceSystem(
          defenseProgress.id,
          defenseProgress.planetId,
          mutateGameSummaryProgress,
          mutateDefenseSystems
        ),
      [defenseProgress.id, defenseProgress.planetId, mutateGameSummaryProgress]
    );
    return (
      <ProgressItem
        key={defenseProgress.id}
        title={title}
        buildStart={defenseProgress.buildStart}
        buildEnd={defenseProgress.buildEnd}
        nextLevel={defenseProgress.defenseValue}
        onFinishBuilding={onFinish}
        cancel={onCancel}
        small={small}
        loading={loading}
      />
    );
  }
);

export const ActiveDefense = observer(({ defenseProgress }: { defenseProgress?: DefenseProgress[] }) => {
  const { t } = useTranslation(["activeBuildings", "common"]);
  const { currentPlanetId } = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { gameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
  const progressDefenses = useMemo(
    () => defenseProgress ?? gameSummaryProgress?.defenseProgress,
    [defenseProgress, gameSummaryProgress?.defenseProgress]
  );
  const noOfItemsToShow = !!defenseProgress ? 1 : 3;
  const small = !!defenseProgress;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior={"outside"} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("activeDefense")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {gameSummaryProgress && gameSummaryProgress.defenseProgress?.length > 0 ? (
                gameSummaryProgress.defenseProgress.map(defenseProgress => (
                  <GridItem w="100%" key={defenseProgress.id}>
                    <DefenseProgressItem defenseProgress={defenseProgress} />
                  </GridItem>
                ))
              ) : (
                <Text my={4}>{t("noActiveProgressDefense")}</Text>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t("close", { ns: "common" })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={"flex"} justifyItems={"center"} flexDirection={"column"}>
        <Text>{t("activeDefense")}</Text>
        {progressDefenses && progressDefenses?.length > 0 ? (
          <>
            {progressDefenses.slice(0, noOfItemsToShow).map(defenseProgress => (
              <DefenseProgressItem key={defenseProgress.id} defenseProgress={defenseProgress} small={small} />
            ))}
            {progressDefenses && progressDefenses?.length > noOfItemsToShow && (
              <Button onClick={onOpen}>{t("showMore", { ns: "common" })}</Button>
            )}
          </>
        ) : (
          <Text my={4}>{t("noActiveProgressDefense")}</Text>
        )}
      </Box>
    </>
  );
});

export default ActiveDefense;
