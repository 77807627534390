import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { observer } from "mobx-react";

const Footer = observer(() => {
  return (
    <Box
      position={"fixed"}
      bottom={0}
      width={"full"}
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      height={"50px"}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text>{`© 2023 New wars. ver. ${process.env.NEXT_PUBLIC_VERSION}`}</Text>
      </Container>
    </Box>
  );
});

export default Footer;
