import { Box, Heading, Select, Text } from "@chakra-ui/react";
import { TopBar } from "@new-wars/common";
import { TopBarModel } from "@new-wars/common/src/TopBar/TopBarModel";
import {
  COMPANY_BUTTON,
  COMPANY_NAME,
  HEADER_NAV_ITEMS,
  STATS_FROM,
  useGameDashboardPlanets,
  useRaceDescription
} from "@new-wars/core";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useCommunicationStore, useUserStore } from "../../hooks";
import { CustomSession } from "../../pages/api/auth/[...nextauth]";
import ActiveBuildings from "../ActiveBuildings/ActiveBuildings";
import NewMessageModal from "../Messages/NewMessageModal";
import PlanetSummary from "../PlanetSummary/PlannetSummary";
import ResourceBar from "../ResourceBar/ResourceBar";

const headerModel = new TopBarModel(COMPANY_NAME, COMPANY_BUTTON, HEADER_NAV_ITEMS);

const Header = observer(({ stats = "all", dashboard = false }: { stats: STATS_FROM; dashboard?: boolean }) => {
  const { t } = useTranslation(["common", "ships"]);
  const { currentPlanetId, selectPlanetId } = useUserStore();
  const { raceDesc } = useRaceDescription();
  const { data, status } = useSession();
  const session = data as CustomSession;
  const { setNewMessageModalOpen } = useCommunicationStore();

  const race = raceDesc?.find(r => r.id === session?.user.raceId);
  const { gameDashboardPlanets } = useGameDashboardPlanets({ isLoggedIn: status === "authenticated" });
  if (status === "loading") {
    return <p>Loading...</p>;
  }
  if (status === "unauthenticated") {
    return (
      <Box mx={{ base: 6, xl: 12 }}>
        <TopBar model={headerModel} setNewMessageModalOpen={setNewMessageModalOpen} />
      </Box>
    );
  }

  return (
    <Box mx={{ base: 6, xl: 12 }}>
      <NewMessageModal />
      <TopBar model={headerModel} setNewMessageModalOpen={setNewMessageModalOpen} />
      <Heading>{`Your profile`}</Heading>
      <Text style={{ fontStyle: "italic" }}>{session?.user?.name}</Text>
      <Text style={{ fontStyle: "italic" }}>{`${t("start")}: ${session?.user.gameStart}`}</Text>
      <Text style={{ fontStyle: "italic" }}>{`${t("race")}: ${race?.name}`}</Text>
      {!dashboard ? (
        <>
          <ResourceBar />
          <ActiveBuildings />
          <Select
            my={4}
            placeholder={t("choosePlanet") as string}
            onChange={e => selectPlanetId(Number.parseInt(e.target.value))}
            value={currentPlanetId}
          >
            {gameDashboardPlanets &&
              gameDashboardPlanets.planets.map(planet => (
                <option key={planet.id} value={planet.planetId}>{`${t("planet")} ${
                  planet.planetName.length > 0 ? planet.planetName : planet.planetId
                }`}</option>
              ))}
          </Select>
          <PlanetSummary stats={stats} />
        </>
      ) : null}
    </Box>
  );
});

export default Header;
