import { Box, Text } from "@chakra-ui/react";
import { ProgressItem } from "@new-wars/common";
import {
  BuildingProgress,
  useBuildingsDescription,
  useGameSummaryBuildings,
  useGameSummaryProgress
} from "@new-wars/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { useBuildingStore, useUserStore } from "../../../hooks";

const BuildingItem = observer(({ buildingProgress, small }: { buildingProgress: BuildingProgress; small: boolean }) => {
  const { onFinishBuilding, cancelBuilding, loading } = useBuildingStore();
  const { currentPlanetId } = useUserStore();
  const { buildingsDesc } = useBuildingsDescription();
  const { mutateGameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
  const { mutateGameSummaryBuildings } = useGameSummaryBuildings(currentPlanetId);
  const title = useMemo(
    () => buildingsDesc?.find(x => x.id === buildingProgress?.buildingId)?.name ?? "",
    [buildingProgress, buildingsDesc]
  );
  const onFinish = useCallback(
    () =>
      onFinishBuilding(
        buildingProgress.id,
        buildingProgress.planetId,
        mutateGameSummaryProgress,
        mutateGameSummaryBuildings
      ),
    [buildingProgress.id, buildingProgress.planetId, mutateGameSummaryProgress, mutateGameSummaryBuildings]
  );
  const onCancel = useCallback(
    () =>
      cancelBuilding(
        buildingProgress.id,
        buildingProgress.planetId,
        mutateGameSummaryProgress,
        mutateGameSummaryBuildings
      ),
    [buildingProgress.id, buildingProgress.planetId, mutateGameSummaryProgress, mutateGameSummaryBuildings]
  );
  return (
    <ProgressItem
      key={buildingProgress.id}
      title={title}
      buildStart={buildingProgress.buildStart}
      buildEnd={buildingProgress.buildEnd}
      nextLevel={buildingProgress.nextLevel}
      onFinishBuilding={onFinish}
      cancel={onCancel}
      small={small}
      loading={loading}
    />
  );
});

export const ActiveBuilding = observer(({ buildingsProgresses }: { buildingsProgresses?: BuildingProgress[] }) => {
  const { t } = useTranslation(["activeBuildings", "common"]);
  const { currentPlanetId } = useUserStore();
  const { gameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
  const progressBuildings = useMemo(
    () => buildingsProgresses ?? gameSummaryProgress?.buildingsProgresses,
    [buildingsProgresses, gameSummaryProgress?.buildingsProgresses]
  );

  return (
    <Box>
      <Text>{t("activeBuildings")}</Text>
      {progressBuildings && progressBuildings?.length > 0 ? (
        progressBuildings.map(buildingProgress => (
          <BuildingItem key={buildingProgress.id} buildingProgress={buildingProgress} small={!!buildingsProgresses} />
        ))
      ) : (
        <Text my={4}>{t("noActiveProgressBuilding")}</Text>
      )}
    </Box>
  );
});

export default ActiveBuilding;
