import { makeObservable, observable } from "mobx";
import { NavItem } from "../types";

export class TopBarModel {
  companyName = "";
  companyButton = "";
  linkItems: NavItem[] = [];
  itemSearchInputValue = "";
  locationSearchInputValue = "";

  constructor(companyName: string, companyButton: string, linkItems: NavItem[]) {
    this.companyName = companyName;
    this.companyButton = companyButton;
    this.linkItems = linkItems;

    makeObservable(this, {
      itemSearchInputValue: observable,
      locationSearchInputValue: observable
    });
  }
}
