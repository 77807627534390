import { Box, ColorProps, Icon, Text, Tooltip } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import {
  PlanetStats,
  STATS_FROM,
  UserPlanet,
  useBuildingsStats,
  useDefenseSystemsStats,
  useGameSummaryProgress,
  useShipUserStats
} from "@new-wars/core";
import { observer } from "mobx-react";
import { useTranslation } from "next-i18next";

import { useMemo } from "react";
import { GiAbstract016, GiBorderedShield } from "react-icons/gi";
import { ImPower } from "react-icons/im";
import { IconType } from "react-icons/lib";
import { TbBuildingCommunity } from "react-icons/tb";
import { useUserStore } from "../../hooks";

export const TextWithIcon = ({
  icon,
  text,
  isOver = false,
  color,
  tooltipLabel = ""
}: {
  icon: IconType;
  text: string;
  isOver?: boolean;
  color: ColorProps["color"];
  tooltipLabel?: string;
}) => {
  return (
    <Box display={"flex"} alignItems="center" py={2} px={2}>
      <Tooltip placement="auto" shouldWrapChildren label={tooltipLabel}>
        <Icon as={icon} mr={2} color={isOver ? "red.600" : color} size={100} />
      </Tooltip>
      <Text color={isOver ? "red.600" : useColorModeValue("black", "white")}>{text}</Text>
    </Box>
  );
};

export const PlanetSummary = observer(
  ({ stats, planet, planetStats }: { stats: STATS_FROM; planet?: UserPlanet; planetStats?: PlanetStats }) => {
    const { currentPlanetId } = useUserStore();
    const isHidden = useMemo(() => stats === "hidden", [stats]);
    const isShip = useMemo(() => stats === "ships", [stats]);
    const isBuilding = useMemo(() => stats === "building", [stats]);
    const isDefence = useMemo(() => stats === "defence", [stats]);
    const gameSummaryProgress = stats === "all" ? useGameSummaryProgress(currentPlanetId).gameSummaryProgress : null;
    const shipUserStat = isShip ? useShipUserStats(currentPlanetId).shipUserStat : null;
    const defenseUserStat = isDefence ? useDefenseSystemsStats(currentPlanetId).defenseSystems : null;
    const buildingsStat = isBuilding ? useBuildingsStats(currentPlanetId).buildingsStats : null;
    const energyUser = isShip
      ? shipUserStat?.planetEnergyStats
      : isDefence
      ? defenseUserStat?.planetEnergyStats
      : gameSummaryProgress?.planet;
    const statsUser = isShip
      ? shipUserStat?.shipsStatsMain
      : isDefence
      ? defenseUserStat?.defenseStatMain
      : gameSummaryProgress?.defenseStat;
    const additionalDefenseStats = defenseUserStat?.defenseStatAdditional;
    const {
      energyAmount,
      energyMaxAmount,
      spaceMaxAmount,
      energyLevel,
      mineFieldAmount,
      spaceAmount,
      mineFieldPercentage,
      shieldGeneratorAmount,
      defenseSystemsAmount,
      shieldGeneratorPercentage,
      additionalDefenseSum,
      percentChange,
      isIonShield,
      attackSum,
      defenseSum
    } = {
      energyAmount: 0,
      energyMaxAmount: 0,
      spaceAmount: 0,
      spaceMaxAmount: 0,
      mineFieldAmount: 0,
      mineFieldPercentage: 0,
      shieldGeneratorAmount: 0,
      defenseSystemsAmount: 0,
      shieldGeneratorPercentage: 0,
      additionalDefenseSum: 0,
      percentChange: 0,
      attackSum: 0,
      defenseSum: 0,
      isIonShield: false,
      ...energyUser,
      ...statsUser,
      ...additionalDefenseStats,
      ...buildingsStat,
      ...planetStats?.defenseStatMain,
      ...planet
    };

    const { t } = useTranslation(["planets", "common"]);
    const attackText = useMemo(() => (isShip ? t("shipAttack") : t("currentAttack")), [t, isShip]);
    const defenseText = useMemo(() => (isShip ? t("shipDefense") : t("currentDefense")), [t, isShip]);
    const tooltipDefenceLabel = useMemo(
      () =>
        isDefence
          ? `${t("minefield", { ns: "common" })}: ${mineFieldAmount} / ${spaceAmount} (${Math.round(
              mineFieldPercentage
            )}%)  
        ${t("covers", { ns: "common" })}: ${shieldGeneratorAmount} / ${defenseSystemsAmount} (${Math.round(
              shieldGeneratorPercentage
            )}%)  
        ${t("ionization", { ns: "common" })}: ${isIonShield}  
        ${t("bonusSum", { ns: "common" })}: ${additionalDefenseSum}`
          : "",
      [
        t,
        isDefence,
        mineFieldAmount,
        spaceAmount,
        mineFieldPercentage,
        shieldGeneratorAmount,
        defenseSystemsAmount,
        shieldGeneratorPercentage,
        isIonShield,
        additionalDefenseSum
      ]
    );

    return !isHidden || planet ? (
      <Box my={4} ml={4} display={"flex"} flexWrap={"wrap"}>
        <TextWithIcon
          isOver={energyAmount > energyMaxAmount}
          icon={ImPower}
          color={"yellow"}
          tooltipLabel={`${t("generalEnergyState")}: ${energyLevel}`}
          text={`${t("currentUsageEnergy")} ${energyAmount}/${energyMaxAmount}`}
        />
        {!isShip ? (
          <TextWithIcon
            isOver={isDefence ? false : spaceAmount > spaceMaxAmount}
            icon={TbBuildingCommunity}
            color={"black"}
            text={`${t("currentUsageSpace")} ${spaceAmount} ${isDefence ? "" : "/"} ${isDefence ? "" : spaceMaxAmount}`}
          />
        ) : null}
        {!isBuilding ? (
          <TextWithIcon
            icon={GiBorderedShield}
            color={"lightblue"}
            text={`${defenseText} ${defenseSum}`}
            tooltipLabel={tooltipDefenceLabel}
          />
        ) : null}
        {!isBuilding ? <TextWithIcon icon={GiAbstract016} color={"red"} text={`${attackText} ${attackSum}`} /> : null}
      </Box>
    ) : null;
  }
);

export default PlanetSummary;
