import { Box } from "@chakra-ui/react";
import { STATS_FROM } from "@new-wars/core";
import { observer } from "mobx-react";
import { ReactNode } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Layout = observer(
  ({
    stats = "hidden",
    dashboard = false,
    children
  }: {
    children: ReactNode;
    stats?: STATS_FROM;
    dashboard?: boolean;
  }) => {
    return (
      <Box marginBottom={"55px"}>
        <Header stats={stats} dashboard={dashboard} />
        {children}
        <Footer />
      </Box>
    );
  }
);

export default Layout;
