import {
  Box,
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { ProgressItem } from "@new-wars/common";
import { ShipsProgress, useGameSummaryProgress, useShipUser, useUserSchema } from "@new-wars/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { useShipStore, useUserStore } from "../../../hooks";

const ShipsProgressItem = observer(
  ({ shipsProgress, small = false }: { shipsProgress: ShipsProgress; small?: boolean }) => {
    const { cancelShipBuild, onFinishShipBuild, loading } = useShipStore();
    const { currentPlanetId } = useUserStore();
    const { mutateShipUser } = useShipUser(currentPlanetId);
    const { userSchema } = useUserSchema(currentPlanetId);
    const { mutateGameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
    const title = useMemo(
      () => userSchema?.find(x => x.id === shipsProgress?.schemaId)?.schemaName ?? "",
      [shipsProgress, userSchema]
    );
    const onFinish = useCallback(
      () => onFinishShipBuild(shipsProgress.id, shipsProgress.planetId, mutateGameSummaryProgress, mutateShipUser),
      [shipsProgress.id, shipsProgress.planetId, mutateGameSummaryProgress, mutateShipUser]
    );
    const onCancel = useCallback(
      () => cancelShipBuild(shipsProgress.id, shipsProgress.planetId, mutateGameSummaryProgress, mutateShipUser),
      [shipsProgress.id, shipsProgress.planetId, mutateGameSummaryProgress, mutateShipUser]
    );
    return (
      <ProgressItem
        key={shipsProgress.id}
        title={title}
        buildStart={shipsProgress.buildStart}
        buildEnd={shipsProgress.buildEnd}
        nextLevel={shipsProgress.defenseValue}
        onFinishBuilding={onFinish}
        cancel={onCancel}
        small={small}
        loading={loading}
      />
    );
  }
);

export const ActiveShips = observer(({ shipsProgress }: { shipsProgress?: ShipsProgress[] }) => {
  const { t } = useTranslation(["activeBuildings", "common"]);
  const { currentPlanetId } = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { gameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
  const progressShips = useMemo(
    () => shipsProgress ?? gameSummaryProgress?.shipsProgress,
    [shipsProgress, gameSummaryProgress?.shipsProgress]
  );
  const noOfItemsToShow = !!shipsProgress ? 1 : 3;
  const small = !!shipsProgress;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior={"outside"} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("activeShips")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {progressShips && progressShips?.length > 0 ? (
                progressShips.map(shipsProgress => (
                  <GridItem w="100%" key={shipsProgress.id}>
                    <ShipsProgressItem shipsProgress={shipsProgress} />
                  </GridItem>
                ))
              ) : (
                <Text my={4}>{t("noActiveProgressShips")}</Text>
              )}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t("close", { ns: "common" })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={"flex"} justifyItems={"center"} flexDirection={"column"}>
        <Text>{t("activeShips")}</Text>
        {progressShips && progressShips?.length > 0 ? (
          <>
            {progressShips.slice(0, noOfItemsToShow).map(shipsProgress => (
              <ShipsProgressItem key={shipsProgress.id} shipsProgress={shipsProgress} small={small} />
            ))}
            {progressShips && progressShips?.length > noOfItemsToShow && (
              <Button onClick={onOpen}>{t("showMore", { ns: "common" })}</Button>
            )}
          </>
        ) : (
          <Text my={4}>{t("noActiveProgressShips")}</Text>
        )}
      </Box>
    </>
  );
});

export default ActiveShips;
