import { Box } from "@chakra-ui/react";
import { ProgressBuildings } from "@new-wars/core";
import { observer } from "mobx-react";
import ActiveBuilding from "./components/ActiveBuilding";
import ActiveDefense from "./components/ActiveDefense";
import ActiveShips from "./components/ActiveShips";
import ActiveTechnologies from "./components/ActiveTechnologies";

const ActiveBuildings = observer(({ progressBuildings }: { progressBuildings?: ProgressBuildings }) => {
  return (
    <Box my={4}>
      <Box display="flex" justifyContent={"space-around"} flexWrap={"wrap"}>
        <ActiveBuilding buildingsProgresses={progressBuildings?.buildingsProgresses} />
        <ActiveTechnologies technologiesProgress={progressBuildings?.technologiesProgress} />
        <ActiveDefense defenseProgress={progressBuildings?.defenseProgress} />
        <ActiveShips shipsProgress={progressBuildings?.shipsProgress} />
      </Box>
    </Box>
  );
});

export default ActiveBuildings;
