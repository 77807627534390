import { Box, Text } from "@chakra-ui/react";
import { ProgressItem } from "@new-wars/common";
import {
  TechnologyProgress,
  useGameSummaryProgress,
  useGameSummaryTechnologies,
  useTechnologiesDescription
} from "@new-wars/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import { useTechnologyStore, useUserStore } from "../../../hooks";

export const ActiveTechnologies = observer(
  ({ technologiesProgress }: { technologiesProgress?: TechnologyProgress }) => {
    const { t } = useTranslation(["activeBuildings", "common"]);
    const { currentPlanetId } = useUserStore();
    const { onFinishTechnology, cancelTechnology, loading } = useTechnologyStore();
    const { gameSummaryProgress, mutateGameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
    const { mutateGameSummaryTechnologies } = useGameSummaryTechnologies(currentPlanetId);
    const { technologiesDesc } = useTechnologiesDescription();
    const technologyProgress = useMemo(
      () => technologiesProgress ?? gameSummaryProgress?.technologiesProgress,
      [gameSummaryProgress?.technologiesProgress, technologiesProgress]
    );
    const onFinishBuilding = useCallback(
      () =>
        technologyProgress &&
        onFinishTechnology(
          technologyProgress.id,
          technologyProgress.planetId,
          mutateGameSummaryProgress,
          mutateGameSummaryTechnologies
        ),
      [technologyProgress?.id, technologyProgress?.planetId, mutateGameSummaryProgress, mutateGameSummaryTechnologies]
    );
    const onCancel = useCallback(
      () =>
        technologyProgress &&
        cancelTechnology(
          technologyProgress.id,
          technologyProgress.planetId,
          mutateGameSummaryProgress,
          mutateGameSummaryTechnologies
        ),
      [technologyProgress?.id, technologyProgress?.planetId, mutateGameSummaryProgress, mutateGameSummaryTechnologies]
    );
    const small = !!technologiesProgress;
    return (
      <Box>
        <Text>{t("activeTechnologies")}</Text>
        {gameSummaryProgress && technologyProgress ? (
          <ProgressItem
            key={technologyProgress.id}
            title={technologiesDesc?.find(desc => desc.id === technologyProgress.technologyId)?.name ?? ""}
            buildStart={technologyProgress.discoverStart}
            buildEnd={technologyProgress.discoverEnd}
            nextLevel={technologyProgress.nextLevel}
            onFinishBuilding={onFinishBuilding}
            cancel={onCancel}
            progressType={2}
            small={small}
            loading={loading}
          />
        ) : (
          <Text my={4}>{t("noActiveProgressTechnologies")}</Text>
        )}
      </Box>
    );
  }
);

export default ActiveTechnologies;
