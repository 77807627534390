import {
  Box,
  ColorProps,
  Grid,
  GridItem,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { IconTypes } from "@new-wars/common";
import { PlanetResources, useGameSummaryProgress } from "@new-wars/core";
import { observer } from "mobx-react-lite";
import { useTranslation } from "next-i18next";
import { AiFillGold } from "react-icons/ai";
import { GiCrystalGrowth, GiFuelTank, GiMetalBar } from "react-icons/gi";
import { useUserStore } from "../../hooks";
const ALMOST_FULL_PERCENTAGE = 0.8;

export const ResourceProgressBar = ({
  title,
  value,
  maxValue,
  production,
  icon,
  bonus,
  color = "black"
}: {
  title: string;
  production: number;
  value: number;
  maxValue: number;
  bonus: number;
  color?: ColorProps["color"];
  icon: IconTypes;
}) => {
  const isAlmostFull = ALMOST_FULL_PERCENTAGE * maxValue <= value;
  const { t } = useTranslation("common");

  return (
    <Popover trigger={"hover"}>
      <PopoverTrigger>
        <GridItem mx={8}>
          <Box display={"flex"} alignItems={"center"}>
            <Icon as={icon} m={2} color={color} fontSize={40} />
            <Text>{`${t("value")}: ${value}`}</Text>
          </Box>

          <Tooltip label={`${t("value")}: ${value}/${maxValue}`} placement="top">
            <Box>
              <Progress
                hasStripe
                colorScheme={isAlmostFull ? "red" : "green"}
                size="sm"
                value={value}
                min={0}
                max={maxValue}
              />
            </Box>
          </Tooltip>
        </GridItem>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Text>{title} </Text>
          <Text>{`${t("currentProduction")}: ${production}/h (${t("bonus")}:${bonus})`}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const ResourceBar = observer(({ planetResource, rows = 6 }: { planetResource?: PlanetResources; rows?: number }) => {
  const { currentPlanetId } = useUserStore();
  const { gameSummaryProgress } = useGameSummaryProgress(currentPlanetId);
  const planetResources = planetResource ? planetResource : gameSummaryProgress && gameSummaryProgress.planetResources;
  const crystal = planetResources?.crystal;
  const frubin = planetResources?.frubin;
  const frurozin = planetResources?.frurozin;
  const orizin = planetResources?.orizin;
  const gold = planetResources?.gold;
  const metal = planetResources?.metal;
  const { t } = useTranslation("common");

  return (
    <Box my={4}>
      {gameSummaryProgress?.planetResources && (
        <Grid
          templateColumns={{
            base: `repeat(${1}, 1fr)`,
            sm: `repeat(${rows ?? 2}, 1fr)`,
            md: `repeat(${rows ?? 3}, 1fr)`,
            lg: `repeat(${rows ?? 4}, 1fr)`,
            xl: `repeat(${rows}, 1fr)`
          }}
          gap={6}
          key={planetResources?.PlanetId}
        >
          {metal && (
            <ResourceProgressBar
              key={"metal"}
              title={t("metal")}
              color={"silver"}
              icon={GiMetalBar}
              value={metal.metalAmount}
              maxValue={metal.metalMaxAmount}
              production={metal.metalProduction}
              bonus={metal.metalProductionBonus}
            />
          )}
          {crystal && (
            <ResourceProgressBar
              key={"crystal"}
              title={t("crystal")}
              color={"#0f52ba"}
              icon={GiCrystalGrowth}
              value={crystal.crystalAmount}
              maxValue={crystal.crystalMaxAmount}
              production={crystal.crystalProduction}
              bonus={crystal.crystalProductionBonus}
            />
          )}
          {frubin && (
            <ResourceProgressBar
              key={"frubin"}
              title={t("frubin")}
              icon={GiMetalBar}
              color={"fuchsia"}
              value={frubin.frubinAmount}
              maxValue={frubin.frubinMaxAmount}
              production={frubin.frubinProduction}
              bonus={frubin.frubinProductionBonus}
            />
          )}
          {orizin && (
            <ResourceProgressBar
              key={"orizin"}
              title={t("orizin")}
              icon={GiMetalBar}
              color={"green"}
              value={orizin.orizinAmount}
              maxValue={orizin.orizinMaxAmount}
              production={orizin.orizinProduction}
              bonus={orizin.orizinProductionBonus}
            />
          )}
          {frurozin && (
            <ResourceProgressBar
              key={"frurozin"}
              title={t("frurozin")}
              icon={GiFuelTank}
              color={"red"}
              value={frurozin.frurozinAmount}
              maxValue={frurozin.frurozinMaxAmount}
              production={frurozin.frurozinProduction}
              bonus={frurozin.frurozinProductionBonus}
            />
          )}

          {gold && (
            <ResourceProgressBar
              key={"gold"}
              title={t("gold")}
              icon={AiFillGold}
              color={"gold"}
              value={gold.goldAmount}
              maxValue={gold.goldMaxAmount}
              production={gold.goldProduction}
              bonus={gold.goldProductionBonus}
            />
          )}
        </Grid>
      )}
    </Box>
  );
});

export default ResourceBar;
